
// Fonts
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600);

// Variables
@import "settings";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Skagga Utilities
@import "vendor/skagga/utilities";

@import "settings";
@import "vendor/skagga/utilities";
@import "components/footer";
@import "components/header";
@import "components/mobile-flyout";
@import "components/video";

body {
    font-family: $sans-primary;
    color:$font-dark;
    font-size:$font-size-base;
    background-color:$background-light;
    @include respond-to(phone) {
        font-size:$font-m-larger;
        overflow-x:hidden;
    }
    @include respond-to(desktop)
    {
        min-width:$desktop-fixed-width;
    }
}

a {
    color:$brand-primary-accent;
}

.products a {
    text-decoration:underline;
}

a.light {
    color:$font-light;
}

a:hover {
    color:$brand-primary;
    text-decoration:none;
}

a.light:hover {
    color:$font-gray;
}

h3 a {
    color:$brand-primary;
}

h3 a:hover {
    color:$font-dark;
}

.news--header h3 a {
    color:$brand-primary-accent;
}

p {
    line-height:$font-line-height;
}

p span {
    color:$font-light;
}

p span a {
    color:$brand-primary-accent;
}

footer p span {
    text-shadow:0 0 0;
}

p span.alt {
    color:$font-accent-alt;
    font-size:$font-size-alt;
}

p.link-under a {
    text-decoration:underline;
}

.center-text {
    text-align:center;
}

label {
    font-weight:normal;
}

h1 {
    font-family:$sans-primary;
    font-size:$font-largest;
    color:$brand-primary-accent;
    font-weight:500;
    // text-shadow: 2px 2px 10px $brand-primary;
    @include respond-to(phone) {
            font-size:$font-m-larger;
    }
}

h1.mobile-squeeze {
    @include respond-to(phone) {
            font-size:$font-m-largey;
    }
}

.page-heading h1 {
    margin:50px 0;
    @include respond-to(phone) {
            margin:25px 0;
    }
}

h2 {
    font-family:$sans-primary;
    font-size:$font-larger;
    color:$brand-primary-accent;
    font-weight:300;
    @include respond-to(phone) {
            font-size:$font-m-larger;
    }
}

h3 {
    font-family:$sans-primary;
    font-size:$font-large;
    color:$brand-primary-accent;
    margin-bottom:0;
    @include respond-to(phone) {
            font-size:$font-m-larger;
    }
}

.background-news h3 {
    text-transform:none;
}

.center-heading {
    text-align:center;
}

.callout {
    display:block;
    font-family:$serif-primary;
    font-size:$font-large;
    color:$brand-secondary-accent;
    font-style:italic;
    border-bottom:1px solid $brand-tertiary-accent;
    border-top:1px solid $brand-tertiary-accent;
    margin:30px 0;
    padding:30px 0;
    @include respond-to(phone) {
            font-size:$font-m-large;
    }
}

h4 {
    font-family:$sans-primary;
    font-size:$font-middle;
    margin-top:15px;
    padding-top:5px;
    text-transform:uppercase;
    color:$font-dark;
    font-weight:300;
}

h4 span {
    color:$font-faded;
    text-transform:none;
}

h5 {
    font-family:$sans-primary;
    font-size:$font-medium;
    border-top:1px solid #555;
    margin-top:15px;
    padding-top:5px;
    text-transform:uppercase;
}

h6 {
    font-family:$serif-primary;
    font-size:$font-smaller;
    font-size:$font-dark;
    @include respond-to(phone) {
        font-size:$font-m-smaller;
    }
}

ul.content-list {
    margin:10px 0 10px 25px;
    padding: 0;
}

ul.content-list li {
    padding: 5px 0 5px 10px;
}

img {
    @include respond-to(phone) {
        margin-bottom:20px;
    }
}

.bottom-border-text {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $brand-secondary-accent;
    clear:both;
}

.page-title-alt h2 {
    color:#fff;
    text-shadow: 0px 0px 15px #000;
}

blockquote {
    font-family:$sans-primary;
    font-size:$font-medium;
    color:$font-light;
    text-align:left;
    margin-top:30px;
    padding-right:10px;
    border:none;
    @include respond-to(phone) {
        font-size:$font-m-small;
    }
}

blockquote.left-border {
    @include respond-to(desktop) {
        border-left:1px solid $brand-primary;
        padding-left:30px;
        margin-left:-30px;
    }
}

blockquote + p {
    float:right;
    margin:0 30px 30px 10px;
}

.media-side {

}

.media-side--body {
    margin:20px 0;
    @include respond-to(desktop)
    {
        margin-top:60px;
    }
}

.upper {
    text-transform:uppercase;
}

.centered-text {
    text-align:center;
}

.upper-serif {
    background:transparent url('../img/line-accents.png') 50% 50% no-repeat;
    text-transform:uppercase;
    font-family:$serif-primary;
    font-size:$font-medium;
    margin:15px 0;
    letter-spacing:2px;
    @include respond-to(phone) {
            font-size:$font-m-smaller;
    }
}

#main {
    //background-color:$background-light;
}

.container {
    width:100%;
    @include respond-to(tablet-and-desktop)
    {
        width:$desktop-fixed-width;
    }
}

.background-home .content-light-bg {
    margin:0;
    padding-bottom:50px;
}

.meet-the-team-bg {
    background:$background-light;
}

.content-light-bg {
    background:$background-light;
    min-height:20px;
    margin:30px 0;
    padding:30px 0 0 0;
    width:100%;
    @include respond-to(desktop)
    {
        margin:50px 0;
        padding:50px 0 0 0;
    }
}

.content-dark-bg {
    background:$background-dark;
    min-height:20px;
    margin:30px 0;
    padding:30px 0 50px 0;
    width:100%;
    @include respond-to(desktop)
    {
        padding:50px 0;
    }
}

.content-testimonial-bg {
    background:$background-light url('../img/page-bg/testimonial-bg.gif') no-repeat;
    min-height:20px;
    padding:70px 0 70px 0;
    width:100%;
    background-size:cover;
    @include respond-to(desktop)
    {
        padding:70px 0;
    }
}

.content-speck-bg {
    background:$background-light url('../img/page-bg/team-bg.gif') no-repeat;
    min-height:20px;
    margin:30px 0 30px 0;
    padding:50px 0 50px 0;
    width:100%;
    background-size:cover;
    @include respond-to(desktop)
    {
        padding:70px 0;
    }
}

.content-speck-bg.page-bottom {
    margin:30px 0 -50px 0;
}

.background-testimonials .content-stripe-bg,
.background-testimonials .content-dim-bg,
.background-testimonials .content-light-bg {
    padding:40px 0;
    text-align:left;
    margin:0;
    //border-top:1px solid #d9d9d9;
    //border-bottom:1px solid #d9d9d9;
}


.content-dim-bg {
    background:$background-dim;
    min-height:20px;
    margin:0;
    padding:70px 0;
    @include respond-to(desktop)
    {

    }
}

.spacer {
    display:block;
    height:80px;
    @include respond-to(site-max)
    {
        height:120px;
    }
    @include respond-to(phone)
    {
        height:0;
    }
}

span.spacer {
    display:block;
    height:40px;
    @include respond-to(phone)
    {
        height:0;
    }
}

.copy-wrap {
    margin-top:20px;
    margin-bottom:20px;
    @include respond-to(desktop)
    {
        margin-top:30px;
        margin-bottom:30px;
    }
}

.copy-wrap-top {
    margin-top:20px;
    @include respond-to(desktop)
    {
        margin-top:40px;
    }
}

.copy-wrap-bot {
    margin-bottom:20px;
    @include respond-to(desktop)
    {
        margin-bottom:40px;
    }
}

.copy-wrap-more {
    margin-top:30px;
    margin-bottom:30px;
    @include respond-to(desktop)
    {
        margin-top:70px;
        margin-bottom:70px;
    }
}

.copy-wrap-alt {
    margin-top:10px;
    margin-bottom:10px;
    @include respond-to(desktop)
    {
        margin-top:20px;
        margin-bottom:20px;
    }
}

.featured-buttons {
    @include respond-to(desktop)
    {
        width:550px;
    }
    margin:0 auto;
    padding:50px 0;
    text-transform:uppercase;
}

.featured-buttons li {
    @include respond-to(desktop)
    {
        display:inline;
        float:left;
    }
    @include respond-to(phone)
    {
        text-align:center;
        margin:-16px 10px;
    }
    list-style-type:none;
}

a.button.first {
    @include respond-to(desktop)
    {
        border-top-left-radius:7px;
        border-bottom-left-radius:7px;
    }
    @include respond-to(phone)
    {
        border-top-left-radius:7px;
        border-top-right-radius:7px;
    }
}

a.button.last {
    @include respond-to(desktop)
    {
        border-top-right-radius:7px;
        border-bottom-right-radius:7px;
    }
    @include respond-to(phone)
    {
        border-bottom-right-radius:7px;
        border-bottom-left-radius:7px;
    }
}

a.button, input.button {
    color:$font-light;
    background-color:$brand-secondary-accent;
    border:1px solid $brand-secondary-accent;
    line-height:25px;
    padding:7px 20px;
    margin:30px 0;
    text-decoration:none;
    white-space:nowrap;
    font-size:$font-medium;
    text-transform:uppercase;
}

a.button:hover, input.button:hover {
    background-color:transparent;
    _box-shadow:1px 1px 1px #67D395;
    border-color:$brand-secondary-accent;
    text-decoration:none;
    color:$brand-secondary-accent;
}

a.button:active, input.button:active {
    background:$font-gray;
    color:$font-light;
    text-shadow:none;
    border-color:$font-gray;
}

a.button--sds {
    background-color:$brand-primary-accent;
    border:1px solid $brand-primary-accent;
}

a.button--sds:hover {
    border-color:$brand-primary-accent;
    color:$brand-primary-accent;
}

.tagline-rotator {
    padding-top:35px;
    @include respond-to(desktop) {
        margin:100px 0;
    }
}

.testimonial p {
    font-style:italic;
    color:$font-faded;
}

.testimonial h4 {
    text-align:right;
}

.copy-wrap img.left {
    @include respond-to(desktop)
    {
        margin:0 10px 10px 0;
        float:left;
    }
    @include respond-to(phone)
    {
        display:block;
        margin:0 auto 20px auto;
    }
}

.copy-wrap img.right {
    @include respond-to(desktop)
    {
        margin:0 0 10px 10px;
        float:right;
    }
    @include respond-to(phone)
    {
        display:block;
        margin:0 auto 20px auto;
    }
}

.img-slide img {
    @include respond-to(desktop) {
        width:800px;
        margin:0 auto -75px auto;
    }
    @include respond-to(phone) {
        box-shadow:none;
        width:100%;
    }
    text-align:center;
    border:1px solid #d9d9d9;
    border-radius:5px;
}

.img-slide-villa img {
    @include respond-to(desktop) {
        width:500px;
        margin:0 auto;
    }
}

.js-slideshow {
    @include respond-to(desktop) {
        width:90%;
        margin:0 auto;
    }
}

.js-slideshow-nav {
    margin:0 50px;
}

.js-slideshow-nav img {
    border-radius:3px;
}

.img-slide p span {
    text-align:center;
    margin-top:-35px;
    color:$font-light;
    display:block;
    text-shadow:1px 1px 4px #000;
    background-color:#fff;
}

.js-slick-prev-arrow {
    display:block;
    height:90px;
    width:20px;
    float:left;
    background:transparent url('../img/prev-arrow.png') no-repeat;
    background-size:contain;
    margin:0 15px 0 15px;
    cursor:pointer;
    z-index:99;
}

.js-slick-next-arrow {
    display:block;
    height:90px;
    width:20px;
    float:right;
    background:transparent url('../img/next-arrow.png') no-repeat;
    background-size:contain;
    margin:0 10px 0 15px;
    cursor:pointer;
    z-index:99;
}

.quadfold div {
    @include respond-to(desktop) {
        margin:20px 0;
        padding:0 20px;
    }
}

.quadfold h5 {
    min-height:40px;
}

.products {
    padding-bottom:40px;
    background:linear-gradient( rgba(255, 255, 255, 0.6), rgba(230, 230, 230, 0.5) );
}

.container-slim {
    @include respond-to(phone) {
        margin:0;
        padding:0;
    }
}

.mobile-vanish {
    @include respond-to(phone) {
      position: absolute;
      top: -9999px;
      left: -9999px;
      overflow:hidden;
    }
}

.desk-vanish {
    @include respond-to(desktop) {
      position: absolute;
      top: -9999px;
      left: -9999px;
      overflow:hidden;
    }
}

.wide-vanish {
    @include respond-to(site-max) {
      position: absolute;
      top: -9999px;
      left: -9999px;
      overflow:hidden;
    }
}

.shelf, .squeeze {
  margin-bottom:0;
  padding-bottom:0;
}

.tuck, .stack {
  margin-top:0 !important;
  padding-top:0 !important;
}

.mobile-tuck, .mobile-stack {
  @include respond-to(phone) {
    margin-top:0 !important;
    padding-top:0 !important;
  }
}

.news--archive-post li {
    margin:0 0 10px 20px;
}

.article {
    margin-bottom:30px;
    border-bottom:1px solid #d9d9d9;
    width:100%
}

.article img {
    width:100%;
    margin:10px 0;
}

td.product-name {
    @include respond-to(desktop) {
        min-width:175px;
    }
    font-weight:500;
    font-size:$font-middle;
    color:$brand-primary-accent;
}

td.sds-download a,
td.tds-download a {
    color:$font-light;
    text-decoration:underline;
}

td.sds-download {
    background-color:$brand-primary-accent;
    padding:10px;
    text-align:center;
}

td.tds-download {
    background-color:$brand-secondary-accent;
    padding:10px;
    text-align:center;
}

.product-block--mobile a {
    text-decoration:underline;
}

.latency-block {
    display:none;
    @include respond-to(desktop) {
        display:block;
        position:absolute;
        width:350px;
        height:50px;
        background-color:$background-light;
        top:220px;
        left:-155px;
        z-index:99;
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
        transform: rotate(90deg);
    }
}

.form-control {
    margin-bottom:20px;
}

form select {
    display: block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.428571429;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    margin-bottom:20px;
}

.product-block--mobile {
    border-top:1px solid #d9d9d9;
    margin-bottom:20px;
}

.alert {
    padding-left:25px;
}

@import "components/slick";
@import "components/slick-theme";





