.alert {
	padding:15px;
	margin-bottom:1rem;
	border:1px solid transparent;
	border-radius:.25rem
}

.alert > p, .alert > ul {
	margin-bottom:0
}

.alert>p+p {
	margin-top:5px
}

.alert-heading {
	color:inherit
}

.alert-link {
	font-weight:700
}

.alert-dismissible {
	padding-right:35px
}

.alert .close {
	display:none;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
}

.alert-dismissible .close {
	position:relative;
	display:inline;
	top:-2px;
	right:-21px;
	color:inherit;
	border:none;
	background:transparent;
}

.alert-success {
	color:#3c763d;
	background-color:#dff0d8;
	border-color:#d0e9c6
}

.alert-success hr {
	border-top-color:#c1e2b3
}

.alert-success .alert-link {
	color:#2b542c
}

.alert-info {
	color:#31708f;
	background-color:#d9edf7;
	border-color:#bcdff1
}

.alert-info hr {
	border-top-color:#a6d5ec
}

.alert-info .alert-link {
	color:#245269
}

.alert-warning {
	color:#8a6d3b;
	background-color:#fcf8e3;
	border-color:#faf2cc
}

.alert-warning hr {
	border-top-color:#f7ecb5
}

.alert-warning .alert-link {
	color:#66512c
}

.alert-danger {
	color:#a94442;
	background-color:#f2dede;
	border-color:#ebcccc
}

.alert-danger hr {
	border-top-color:#e4b9b9
}

.alert-danger .alert-link {
	color:#843534
}

