/*------------------------------------*\
    #CLEARFIX
\*------------------------------------*/

/**
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix class with Sass to avoid the `.u-clearfix` class appearing
 * over and over in your markup.
 *
 * .selector {
 *     @extend .u-clearfix;
 * }
 *
 */
.u-clearfix,
%u-clearfix {
    &:after
    {
        content: "";
        display: table;
        clear: both;
    }
}