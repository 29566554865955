header {
	display:block;
	min-height:220px;
}

.site-bg header {
	background:url(../img/page-bg/about-bg.jpg) 0% 0% no-repeat;
	@include respond-to(desktop)
	{
		background-size:cover;
	}
	@include respond-to(phone)
	{
		min-height:220px;
	}
}

.background-home header {
	background-image:url(../img/home-bg/Leepoxy_Slides-Chem.png);
	background-position:50% 0;
	background-repeat:no-repeat;
	background-size: cover;
	padding-bottom:50px;
	@include respond-to(site-max)
	{
		background-position:0 30%;
	}
	@include respond-to(desktop)
	{
		min-height:500px;
	}
}

.background-about header {
	background:url(../img/page-bg/about-bg.jpg) 0% 0% no-repeat;
	@include respond-to(desktop)
	{
		background-size:cover;
	}
	@include respond-to(phone)
	{
		min-height:220px;
	}
}

.background-epoxy header {
	background:url(../img/page-bg/epoxy.png) 0% 0% no-repeat;
	@include respond-to(desktop)
	{
		background-size:cover;
	}
	@include respond-to(phone)
	{
		min-height:220px;
	}
}

.background-bf3 header {
	background:url(../img/page-bg/bf3.png) 0% 0% no-repeat;
	@include respond-to(desktop)
	{
		background-size:cover;
	}
	@include respond-to(phone)
	{
		min-height:220px;
	}
}

.background-bcl3 header {
	background:url(../img/page-bg/bcl3.png) 0% 0% no-repeat;
	@include respond-to(desktop)
	{
		background-size:cover;
	}
	@include respond-to(phone)
	{
		min-height:220px;
	}
}

.background-news header {
	background:url(../img/page-bg/about-bg.jpg) 0% 0% no-repeat;
	@include respond-to(desktop)
	{
		background-size:cover;
	}
	@include respond-to(phone)
	{
		min-height:220px;
	}
}

.background-testimonials header {
	background:url(../img/page-bg/about-bg.jpg) 0% 0% no-repeat;
	@include respond-to(desktop)
	{
		background-size:cover;
	}
	@include respond-to(phone)
	{
		min-height:220px;
	}
}

.background-contact header {
	background:url(../img/page-bg/about-bg.jpg) 0% 0% no-repeat;
	@include respond-to(desktop)
	{
		background-size:cover;
	}
	@include respond-to(phone)
	{
		min-height:220px;
	}
}

.mobile-button {
	@include respond-to(desktop)
	{
		display:none;
	}

}

.hamburger-label {
	font-size:$font-m-middle;
	font-weight:100;
	color:$font-dark;
	position:fixed;
	top:25px;
	right:15px;
	font-family:$sans-primary;
	border:1px solid $font-dark;
	padding:1px 5px;
	border-radius:5px;
}

nav {
	@include respond-to(phone)
	{
		border-left:1px solid #d9d9d9;
		height:100%;
		background:$background-light;
	}
}

.nav-block {
	background:rgba(255, 255, 255, 0.8);
	padding:24px 0 15px 0;

	@include respond-to(desktop)
	{
		padding:10px 0;
		height:60px;
	}
}

.nav-block__wrap {
	display:none;
	margin:10px 0 0 0;
	//background-color: transparent;

	@include respond-to(desktop)
	{
		display:block;
		text-align:right;
		margin-right:10px;
		//border:none;
	}
}

.nav > li {
	border-bottom:1px solid #d9d9d9;
	font-family:$sans-primary;
	line-height:12px;

	@include respond-to(phone)
	{
		margin:0;
		font-size:$font-medium;
	}

	@include respond-to(desktop)
	{
		display:inline-block;
		padding:0 6px 0 0;
		font-size:$font-medium;
		height:auto;
		border-bottom:none;
	}
}

.nav > li:hover {
	@include respond-to(phone)
	{
		border-bottom:1px solid $font-faded;
	}
}


.nav > li + li {

	@include respond-to(desktop)
	{
		// border-left:1px solid $font-faded;
		padding-left:9px;
	}
}

.nav li a {
	color:$font-dark;
}

.nav li a:hover {
	background:none;
	@include respond-to(desktop)
	{
		text-decoration:underline;
	}
}

.nav > li > .nav_link {

	color:$font-dark;

	@include respond-to(phone)
	{
		padding:1em;
		display:block;
	}
}

.nav > li > .nav_link.current {
	color:$brand-primary-accent;
}

.nav-phone {
	font-size:$font-m-medium;
	@include respond-to(desktop)
	{
		font-size:$font-medium;
	}
}

a.nav-block_logo {
	display:block;
	background:transparent url(../img/leepoxy-logo.svg) 0px 15px no-repeat;
	background-size:contain;
	width:200px;
	height:60px;
	float:left;
	margin-top:-12px;
	@include respond-to(phone)
	{

	}
}

.dropdown-item {
	display:block;
	border-bottom:1px solid #d9d9d9;
	padding:5px 10px;
	line-height:25px;
	margin:0;
}

.dropdown-item:last-child {
	border:none;
}

nav li.dropdown a.current {
	color:$brand-primary-accent;
}





