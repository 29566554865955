// Hide the text for this element
// @extend %u-screen-reader-text

// Screen reader only text
.u-screen-reader-text,
%u-screen-reader-text {
  position: absolute;
  top: -9999px;
  left: -9999px;
  overflow:hidden;
}