/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/
/**
 * Define your breakpoints for small (phone) and large (desktop) devices.
 * Nothing else needs to be edited.
 */
$break-small: 480px !default;
$break-large: 480px !default;
$break-fixed-width: 1150px !default;
$break-ultra-wide: 1500px !default;

/*------------------------------------*\
    $INTRODUCTION
\*------------------------------------*/
/**
 * Out of the box we cater to the following breakpoints:
 *
 * phone    -- palm-based devices, like phones (and small tablets)
 * tablet   -- lap-based devices, like tablets, iPads or laptops
 * desktop  -- stationary devices, like desktop computers
 * retina   -- devices with retina support
 *
 * List devices from smallest to largest when targeting multiple devices.
 *
	.profile-pic {

		width: 100%;

		@include respond-to(phone) {
			background: blue;
		}
		@include respond-to(tablet) {
			width: 125px;
		}
		@include respond-to(desktop) {
			width: 250px;
		}
		@include respond-to(phone, tablet) {
			height: 50px;
		}
		@include respond-to(tablet, desktop) {
			color: red;
		}
		@include respond-to(retina) {
			width: 100%;
		}
	}

 */

/*------------------------------------*\
    $MIXINS
\*------------------------------------*/
/**
 * The devices and queries. Multiple device selectors are named from smallest to largest.
 */
$breakpoint-queries: (
	phone: '(max-width: #{$break-small})',
	tablet: '(min-width: #{$break-small + 1}) and (max-width: #{$break-large - 1})',
	desktop: '(min-width: #{$break-large})',
	site-width: '(max-width: #{$break-fixed-width})',
	site-max: '(min-width: #{$break-ultra-wide})',
	phone-and-tablet: '(max-width: #{$break-large - 1})',
	tablet-and-desktop: '(min-width: #{$break-small + 1})',
	retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)'
) !default;

/**
 * These mixins are for the library to use only, you should not need to modify
 * them at all.
 *
 * Enclose a block of code with a media query for a specific device.
 */
@mixin respond-to($devices...)
{
	$device: '';

	@each $device-name in $devices {
		$device: if($device == '', #{$device-name}, #{$device}-and-#{$device-name});
	}

	$selector: map-get($breakpoint-queries, $device);

	@if $selector {
		@media only screen and #{$selector} { @content; }
	}
	@else {
		@warn "Breakpoint query '#{$device}' does not exist"
	}
}