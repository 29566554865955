video { display: block; }

video#homebgvid {
    position:fixed;
    min-width:100%;
    min-height:650px;
    width:auto;
    height:auto;
    z-index:-100;
    background-size:cover;
    opacity:.4;
    @include respond-to(phone)
    {
        display:none;
    }
}