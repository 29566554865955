$flash-default-background: #BB574E !default;
$flash-info-background: #4c96ba !default;
$flash-warning-background: #fff9d8 !default;
$flash-danger-background: #BB574E !default;
$flash-success-background: #6dba4c !default;

.c-flash-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10000;
  background: $flash-default-background;
  color: #FFF;
  border: none;
  border-radius: 0;
  padding: 20px 40px;
  box-shadow: 0 0 10px #BBB;
  min-width: 380px;
  text-align: center;
}

.c-flash-message__body {

}

/* States */
.c-flash-message.is-info {
  background: $flash-info-background;
}

.c-flash-message.is-success {
  background: $flash-success-background;
}

.c-flash-message.is-warning {
  background: $flash-warning-background;
}

.c-flash-message.is-danger {
  background: $flash-danger-background;
}
